    <template>
        <div class="alert-container">
            <div class="button-container">
                <button class="button is-primary button-merits"
                        @click="$router.push('/meritUser/create')">
                    {{ i18n ('Give merits') }}
                </button>
            </div>
            <div v-for="item in combined" :key="item.id">
                <div :class="{'alert': true, 'alert-primary':
                 item.merit, 'alert-success': item.badge}" role="alert">
                <div class="alert-content">
                        <div v-if="item.avatar" class="avatar-container">
                            <img
                                class="post-author-avatar"
                                alt="avatar"
                                :src="route('core.avatars.show', { avatar: item.avatar.id })">
                        </div>
                        <div class="alert-info">
                            <h4 class="alert-heading" v-if="item.merit">
                                <span class="user-name">{{ item.person.name }}</span>
                                {{ i18n('Has earned merit') }}"
                                {{ i18n(item.merit.name) }}"
                                {{ i18n('given by') }}
                                {{ i18n(item.sender.name) }}
                            </h4>
                                <h4 class="alert-heading" v-if="item.badge">
                                <span class="user-name">{{ item.person.name }}</span>
                                {{ i18n('Has earned badge') }}"
                                {{ i18n(item.badge.name) }}"
                                </h4>
                            <p class="alert-points">
                                {{ i18n('and got') }}
                                {{ i18n(item.points) }}
                                {{ i18n('points') }}
                            </p>
                        </div>
                    </div>
                    <div class="medal">
                    <img v-if="item.merit"
                         :src="'/storage/' + item.merit.image" alt="Imagen">
                        <img v-if="item.badge"
                             :src="'/storage/' + item.badge.image" alt="Imagen">
                    </div>
                </div>
            </div>
        </div>
    </template>

<script>

import i18n from '@enso-ui/ui/src/modules/plugins/i18n';

export default {
    name: 'UserIndex',
    inject: ['http', 'route', 'http'],
    data() {
        return {
            merits: [],
            combined: [],
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        i18n,
        fetch() {
            this.http
                .get(this.route('meritUser.userMerits'))
                .then(({ data }) => {
                    this.combined = data.combined;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../sass/enso.scss';

.alert-container {
    margin-bottom: 1.1rem;
}

.alert {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 0.5rem 0.75rem 0.6rem;
    flex-direction: column;
}

.avatar-container {
    margin-right: 1rem;
}

.post-author-avatar {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
}

.alert-content {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.alert-info {
    flex: 1;
}

.alert-heading {
    color: inherit;
    font-size: 1rem;
}

.alert-points {
    font-size: 0.8rem;
}

.alert-primary {
    color: $mediumDarkBlue;
    background-color: $blueToneOne;
    border-color: $blueToneTwo;
}
.alert-success {
    color: $darkGreen;
    background-color: $lightGreen;
    border-color: $mediumLightGreen;
}
.button-container{
    display: flex;
}

.medal {
    width: 2.6rem;
    height: auto;
    //margin-left: auto;
    display: flex;
    align-items: center;
}
.user-name{
    font-weight: 600;
}
@media (max-width: 748px)
{
    .medal{
    width: 3.5rem;
    height: 3.5rem;
    }
}
.button-merits{
    margin: auto;
}
</style>
